import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import SummaryPage from 'features/common/summaryPage';
import { Stack } from '@mui/material';
import DocumentosFormalizarItem from './documentoFormalizarItem';
import { getDocumentStatus, renderStatusItens } from './documentoFormalizarStatus/statusUtils';

const DocumentosFormalizar = ({ listaDocumentosFormalizar }) => {
  const renderItemStatusDealer = () => {
    const statusSummary = listaDocumentosFormalizar?.flatMap(getDocumentStatus)
      .filter((status) => status ?? null);
    return renderStatusItens(statusSummary);
  };
  return (
    <div>
      {!(_.isEmpty(listaDocumentosFormalizar) || _.isNull(listaDocumentosFormalizar))
      && (
        <Stack>
          <SummaryPage
            level={0}
            title="Documentos para formalizar"
            IconTitle={renderItemStatusDealer()}
          >
            {listaDocumentosFormalizar?.map((doc, index) => (
              <DocumentosFormalizarItem
                key={index}
                item={doc}
                indexList={index}
              />
            ))}
          </SummaryPage>
        </Stack>
      )}
    </div>
  );
};

DocumentosFormalizar.propTypes = {
  listaDocumentosFormalizar: PropTypes.array,
};

DocumentosFormalizar.defaultProps = {
  listaDocumentosFormalizar: [],
};

export default DocumentosFormalizar;
