import { React, useEffect, useState } from 'react';
import './buttonsFooter.scss';
import { useHistory } from 'react-router-dom';
import NewButton from 'features/common/newButton/newButton';
import PropTypes from 'prop-types';
import AlertFilledIcon from 'assets/icons/alert-filled';
import colors from 'assets/styles/colors';
import AlertModal from 'features/common/alertModal';
import SnackbarItem from 'modules/snackbar/snackbarItem/snackbarItem';
import { SnackbarContainer } from 'modules/snackbar/snackbar.style';
import { ClickAwayListener } from '@mui/material';

const configuracaoPadrao = () => ({
  textButton: '',
  textAlertButton: '',
  statusButton: '',
  titleModal: '',
  textModal: '',
  statusModal: '',
});

const configuracaoDealer = (documentos) => ({
  ...documentos,
  textButton: 'Enviar para Cadastro',
  textAlertButton: 'É necessário inserir um documento para enviar ao cadastro',
  statusButton: 'documentos_atualizados_dealer',
  titleModal: 'Há campos não preenchidos na lista.',
  textModal: 'Ainda existem documentos á serem anexados nesta lista de cadastro. Deseja enviar a lista com documentos pendentes?\nVocê poderá adicionar os documentos restantes posteriormente.',
  statusModal: 'documentos_enviados_parcialmente',
});

const configuracaoJuridico = (documentos) => ({
  ...documentos,
  textButton: 'Enviar para financiamento rede',
  textAlertButton: 'É necessário inserir um documento para enviar ao financiamento rede',
  statusButton: 'docs_juridico_atualizado_dealer',
  titleModal: 'Deseja enviar a lista com documentos pendentes?',
  textModal: 'Ainda existem documentos à serem anexados nesta lista. Você poderá adicionar os documentos complementares restantes posteriormente.',
  statusModal: 'docs_juridico_enviados_parcialmente',
});

const configuracaoFormalizar = (documentos) => ({
  ...documentos,
  textButton: 'Enviar para financiamento rede',
  textAlertButton: 'É necessário inserir um documento para enviar ao financiamento rede',
  statusButton: 'docs_formalizar_atualizado_dealer',
  titleModal: 'Deseja enviar a lista com documentos pendentes?',
  textModal: 'Ainda existem documentos à serem anexados nesta lista. Você poderá adicionar os documentos para formalizar restantes posteriormente.',
  statusModal: 'docs_formalizar_enviados_parcialmente',
});

const getConfiguracao = (
  statusLimite,
  documentosCadastro,
  documentosJuridico,
  documentosFormalizar,
) => {
  switch (statusLimite) {
    case 'pendente_documentos':
    case 'documentos_enviados_parcialmente':
    case 'documentos_atualizados_dealer':
      return configuracaoDealer(documentosCadastro);
    case 'pendente_docs_juridico_dealer':
    case 'docs_juridico_enviados_parcialmente':
    case 'docs_juridico_atualizado_dealer':
      return configuracaoJuridico(documentosJuridico);
    case 'pendente_docs_formalizar_dealer':
    case 'docs_formalizar_enviados_parcialmente':
    case 'docs_formalizar_atualizado_dealer':
      return configuracaoFormalizar(documentosFormalizar);
    default:
      return configuracaoPadrao();
  }
};

const ButtonsFooter = ({
  limite, enviarProposta, documentosCadastro, documentosJuridico, documentosFormalizar,
  anexosFormalizar, anexosComplementares, existNewAnexos, removeNewAnexos,
}) => {
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const [openModalCancelarEnvio, setOpenModalCancelarEnvio] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (existNewAnexos) {
        event.preventDefault();
        return '';
      }
      return undefined;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [existNewAnexos]);

  const navigateBack = () => {
    history.replace('/solicitacao-credito/aprovacoes/limites-aprovados');
  };

  const cancelAction = () => {
    const todosAnexosEnviados = !existNewAnexos;

    if (todosAnexosEnviados) navigateBack();
    else setOpenModalCancelarEnvio(true);
  };

  const cancelarEnvioConfirmAction = () => {
    removeNewAnexos();
    navigateBack();
  };

  const cancelarEnvioVoltarAction = () => {
    setOpenModalCancelarEnvio(false);
    setShowSnackbar(true);
  };

  const config = getConfiguracao(
    limite?.statusLimite,
    documentosCadastro,
    documentosJuridico,
    documentosFormalizar,
  );

  const handleButtonClick = () => {
    if (config.preenchido) {
      enviarProposta(
        limite?.idLimite,
        config.statusButton,
        anexosFormalizar,
        anexosComplementares,
      );
    } else if (config.parcial) {
      setOpenModal(true);
    }
  };

  return limite && (
    <>
      <div className="buttons-footer-documentos">
        <NewButton
          className="dark_gray_border"
          onClick={cancelAction}
        >
          <span>Cancelar</span>
        </NewButton>
        {config.textButton && (
          <NewButton
            className="dark_green"
            openPopper={!config.parcial}
            onClick={handleButtonClick}
            alertCardTitle={config.textAlertButton}
            icone={<AlertFilledIcon width="8" height="8" />}
            colorBase={colors.error_color_400}
          >
            <span>{config.textButton}</span>
          </NewButton>
        )}
      </div>
      {
        showSnackbar && (
          <ClickAwayListener onClickAway={() => setShowSnackbar(false)}>
            <SnackbarContainer customStyle={{
              alignItems: 'flex-end',
              right: '40px',
              bottom: '55px',
              left: 'unset',
            }}
            >
              <SnackbarItem
                id={999}
                message="Clique em Enviar para Financiamento rede, para continuar o processo."
                onClose={() => setShowSnackbar(false)}
                type="info"
                delay={10000}
              />
            </SnackbarContainer>
          </ClickAwayListener>
        )
      }
      <AlertModal
        buttonAction={() => enviarProposta(
          limite?.idLimite,
          config.statusModal,
          anexosFormalizar,
          anexosComplementares,
        )}
        title={config.titleModal}
        subtitle={config.textModal}
        textRedButton="Enviar parcialmente"
        textGrayButton="Cancelar"
        openModal={openModal}
        setOpen={setOpenModal}
      />
      <AlertModal
        buttonAction={cancelarEnvioConfirmAction}
        title="Cancelar"
        subtitle={'Existem anexos que ainda não foram enviados para o Financiamento Rede, '
          + 'caso clique em cancelar os anexos serão perdidos. Tem certeza que deseja cancelar?'}
        textRedButton="Sim, cancelar"
        textGrayButton="Voltar"
        openModal={openModalCancelarEnvio}
        setOpen={setOpenModalCancelarEnvio}
        closeAction={cancelarEnvioVoltarAction}
      />
    </>
  );
};

ButtonsFooter.propTypes = {
  limite: PropTypes.object,
  enviarProposta: PropTypes.func,
  documentosCadastro: PropTypes.object,
  documentosJuridico: PropTypes.object,
  documentosFormalizar: PropTypes.object,
  existNewAnexos: PropTypes.bool,
  removeNewAnexos: PropTypes.func,
  anexosFormalizar: PropTypes.object,
  anexosComplementares: PropTypes.object,
};

ButtonsFooter.defaultProps = {
  limite: null,
  enviarProposta: () => { },
  documentosCadastro: { parcial: false, preenchido: false },
  documentosJuridico: { parcial: false, preenchido: false },
  documentosFormalizar: { parcial: false, preenchido: false },
  existNewAnexos: false,
  removeNewAnexos: () => { },
  anexosFormalizar: null,
  anexosComplementares: null,
};

export default ButtonsFooter;
