import { connect } from 'react-redux';

import DownloadDocumento from './downloadDocumento';
import operations from '../redux/operations';

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => ({
  downloadDocumento: (idDocumento) => {
    (dispatch(operations.downloadDocumentoDoCadastro(idDocumento)));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DownloadDocumento);
