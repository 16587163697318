import { React } from 'react';
import PropTypes from 'prop-types';
import FileButton from 'features/common/fileButton/fileButton';

const DownloadDocumento = ({
  downloadDocumento,
  documento,
  label,
}) => {
  const download = () => {
    downloadDocumento(documento.id);
  };
  return (
    <FileButton
      downloadFile={download}
      erro={false}
      nome={documento.nome}
      tamanho={documento.tamanho}
      label={label}
      showDeleteButton={false}
      isOnlyDownload
    />
  );
};

DownloadDocumento
  .propTypes = {
    downloadDocumento: PropTypes.func,
    documento: PropTypes.object,
    label: PropTypes.string,
  };

DownloadDocumento
  .defaultProps = {
    downloadDocumento: () => {},
    label: 'Documento',
    documento: {},
  };

export default DownloadDocumento;
