import { makeStyles } from '@material-ui/styles';
import colors from 'assets/styles/colors';

export const useStyles = makeStyles({
  input: {
    display: 'none',
  },
  loading: {
    marginTop: 3,
  },
  textoDocumento: {
    fontSize: '12px',
    color: (props) => (props.documentoAnexado ? `${colors.process_color_300}` : `${colors.secundary_color_600}`),
    textDecoration: (props) => props.documentoAnexado && 'underline',
    background: 'transparent !important',
  },
  textoLabel: {
    textAlign: 'left',
    color: `${colors.secundary_color_700}`,
    fontSize: '12px',
    marginBottom: '6px',
    marginLeft: '12px',
  },
  textoError: {
    textAlign: 'left',
    color: `${colors.error_color_300}`,
    fontSize: '12px',
    marginBottom: '6px',
    marginLeft: '12px',
  },
  icone: {
    color: '#656C83',
    marginRight: '8px',
  },
  downloadIcone: {
    color: '#656C83',
    marginRight: '12px',
    position: 'absolute',
    right: 0,
  },
  iconeExcluir: {
    fontSize: '16px',
  },
  botaoExcluir: {
    marginLeft: '8px',
    marginTop: '4px',
  },
  boxBotao: {
    width: '100%',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid',
    padding: '8px 12px',
    backgroundColor: '#E4E9F25C',
    borderColor: (props) => props.borderColor,
    borderRadius: '4px',
  },
  container: {
    marginLeft: '6px',
    display: 'flex',
    background: 'white',
    position: 'relative',
    '& .MuiButtonBase-root': {
      justifyContent: 'normal',
    },
  },
  updateButton: {
    width: '100%',
  },
});
