import { connect } from 'react-redux';

import UploadDocumento from './uploadDocumento';
import operations from '../redux/operations';

const mapStateToProps = ({ limitesAprovadosCadastro }) => ({
  gerenciadorDocumentos: limitesAprovadosCadastro.gerenciadorDocumentos,
});

const mapDispatchToProps = (dispatch) => ({
  uploadDocumento: (file, idPessoaDocumento, nomeDocumento) => {
    (dispatch(operations.uploadDocumento(file, idPessoaDocumento, nomeDocumento)));
  },
  downloadDocumento: (idDocumento, idPessoaDocumento, nomeDocumento) => {
    (dispatch(operations.downloadDocumento(idDocumento, idPessoaDocumento, nomeDocumento)));
  },
  deleteDocumento: (idPessoaDocumentacao, nomeDocumento) => {
    (dispatch(operations.deleteDocumento(idPessoaDocumentacao, nomeDocumento)));
  },
  setErroDocumento: (idPessoaDocumento, nomeDocumento, textoErro) => {
    (dispatch(operations.setErroDocumento(idPessoaDocumento, nomeDocumento, textoErro)));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadDocumento);
