import React, {
  useEffect, useMemo, useState,
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import SummaryPage from 'features/common/summaryPage';
import UploadMultiplosAnexos from 'features/common/uploadMultiplosAnexos/uploadMultiplosAnexos';
import AlertCard from 'features/common/alertCard/alertCard';
import InfoIcon from '@material-ui/icons/Info';

const DocumentoFormalizarForm = ({
  indexList, enabled,
  disableDealerDocEdition, documento,
  getDocumentoList,
  uploadAnexo, downloadAnexo,
  handleInvalidAnexo, deleteAnexo,
}) => {
  const listaArquivos = documento?.listaArquivos;

  const isAnexoDealer = documento?.tipoEnvioDocumento?.some((tipo) => tipo === 'anexo_do_dealer');
  const isDocusign = documento?.tipoEnvioDocumento?.some((tipo) => tipo === 'docsign');
  const isEnviarDocOriginal = documento?.tipoEnvioDocumento?.some((tipo) => tipo === 'enviar_doc_original');
  const isEmptyTipoEnvio = _.isEmpty(documento?.tipoEnvioDocumento);
  const documentosJuridico = useMemo(() => listaArquivos?.filter((arquivo) => arquivo?.tipo === 'juridico'), [listaArquivos]);
  const documentosDealer = useMemo(() => listaArquivos?.filter((arquivo) => arquivo?.tipo === 'dealer'), [listaArquivos]);
  const isOnlyDocusign = (isDocusign && !isAnexoDealer && !isEnviarDocOriginal);
  const isOnlyEnviarDocOriginal = (isEnviarDocOriginal && !isAnexoDealer && !isDocusign);

  const [anexosLength, setAnexosLength] = useState(documentosDealer?.length);

  const insertDescriptionAlert = () => {
    if (isOnlyDocusign) {
      return 'Este documento está em processo de assinatura pelo docusing.';
    }
    if (isOnlyEnviarDocOriginal) {
      return 'É necessário o envio do documento original.';
    }
    if (isEmptyTipoEnvio) {
      return 'Para este documento não é necessário envio de anexo nem documento original.';
    }
    return '';
  };

  useEffect(() => {
    if (enabled) getDocumentoList();
  }, [enabled]);

  const handleUploadAnexo = async (file) => {
    const newAnexo = await uploadAnexo(file, documento?.id, indexList);
    setAnexosLength(anexosLength + 1);
    return newAnexo;
  };

  const handleDeleteAnexo = async (anexo) => {
    await deleteAnexo(anexo?.nomeGuid, indexList);
    setAnexosLength(anexosLength - 1);
  };

  return (
    <Box>
      {!isOnlyDocusign && (
      <>
        {(documentosJuridico?.length > 0)
          && (
            <SummaryPage
              open
              level={6}
              title="Documentos jurídico"
            >
              <Box padding="16px">
                <UploadMultiplosAnexos
                  downloadDocumento={downloadAnexo}
                  documentos={documentosJuridico}
                  disableUpload
                  disableDelete
                  handleInvalidAnexo={handleInvalidAnexo}
                />
              </Box>
            </SummaryPage>
          )}
        {(isAnexoDealer && !(enabled || isEmptyTipoEnvio || isOnlyEnviarDocOriginal)) && (
        <SummaryPage
          open
          level={6}
          title="Documentos dealer"
        >
          <Box padding="16px">
            <UploadMultiplosAnexos
              uploadDocumento={handleUploadAnexo}
              disableUpload={disableDealerDocEdition}
              downloadDocumento={downloadAnexo}
              deleteDocumento={handleDeleteAnexo}
              documentos={documentosDealer}
              disableDelete
            />
          </Box>
        </SummaryPage>
        )}
      </>
      )}
      {(isOnlyDocusign || isOnlyEnviarDocOriginal || isEmptyTipoEnvio) && (
        <AlertCard
          icone={<InfoIcon style={{ fill: '#788CDA', width: '20', height: '20' }} />}
          title={insertDescriptionAlert()}
          colorBase="rgba(0, 99, 247, 0.08)"
          borderColor="rgba(165, 178, 230, 1)"
          width="fit-content"
          height="20px"
          padding="8px 12px 8px 8px"
          margin="16px"
          lineHeight="21px"
          alertCardContent="0px"
          docInfo
        />
      )}
    </Box>
  );
};
DocumentoFormalizarForm.propTypes = {
  indexList: PropTypes.number.isRequired,
  enabled: PropTypes.bool,
  disableDealerDocEdition: PropTypes.bool,
  documento: PropTypes.object,
  getDocumentoList: PropTypes.func,
  uploadAnexo: PropTypes.func,
  downloadAnexo: PropTypes.func,
  handleInvalidAnexo: PropTypes.func,
  deleteAnexo: PropTypes.func,
};
DocumentoFormalizarForm.defaultProps = {
  enabled: false,
  disableDealerDocEdition: false,
  documento: {},
  getDocumentoList: () => {},
  uploadAnexo: () => {},
  downloadAnexo: () => {},
  handleInvalidAnexo: () => {},
  deleteAnexo: () => {},
};

export default DocumentoFormalizarForm;
