import { createSelector } from 'reselect';
import _ from 'lodash';

const documentosCadastro = createSelector(
  (state) => state?.gerenciadorDocumentos,
  (gerenciadorDocumentos) => ({
    parcial: gerenciadorDocumentos?.some((item) => item?.nome),
    preenchido: gerenciadorDocumentos?.every((item) => item?.nome),
  }),
);

const documentosJuridico = createSelector(
  (state) => state?.listaDocumentosComplementares?.listaDados,
  (listaDados) => ({
    parcial: listaDados?.some(
      (pessoa) => pessoa?.documentoPendente.some(
        (documento) => documento?.documentoNome,
      ),
    ),
    preenchido: listaDados?.every(
      (pessoa) => pessoa?.documentoPendente.every(
        (documento) => documento?.documentoNome,
      ),
    ),
  }),
);

const documentosFormalizar = createSelector(
  (state) => state?.listaDocumentosFormalizar?.listaDados,
  (listaDados) => ({
    parcial: listaDados?.some(
      (documento) => (
        documento?.tipoEnvioDocumento?.some((tipo) => (tipo === 'anexo_do_dealer'
          ? documento?.listaArquivos?.some(
            (arquivo) => arquivo.tipo === 'dealer' && arquivo.nomeGuid !== '',
          )
          : false))
      ),
    ),
    preenchido: listaDados?.every(
      (documento) => (
        documento?.tipoEnvioDocumento?.every((tipo) => (tipo === 'anexo_do_dealer'
          ? documento?.listaArquivos?.some(
            (arquivo) => arquivo.tipo === 'dealer' && arquivo.nomeGuid !== '',
          )
          : true))
      ),
    ),
  }),
);

const pendenciaDocumentosFormalizar = createSelector(
  (state) => state?.listaDocumentosFormalizar?.listaDados,
  (listaDados) => listaDados?.some(
    (documento) => (documento?.tipoEnvioDocumento?.some((tipo) => tipo === 'anexo_do_dealer')
      && !documento?.listaArquivos?.some(
        (arquivo) => arquivo.tipo === 'dealer' && arquivo.nomeGuid !== '',
      )),
  ),
);

const checkNewAnexosDocumentosFormalizar = createSelector(
  (state) => state?.listaDados,
  (listaDados) => ({
    status: listaDados?.some((item) => {
      const documentosDealer = item?.listaArquivos?.filter((arquivo) => arquivo?.tipo === 'dealer');
      return documentosDealer.some((anexos) => {
        if (anexos.nomeGuid !== '' && (_.isNull(anexos.id) || _.isUndefined(anexos.id))) {
          return true;
        }
        return false;
      });
    }),
  }),
);
const anexosFormalizar = createSelector(
  (state) => state?.listaDocumentosFormalizar?.listaDados,
  (listaDados) => {
    const result = listaDados?.reduce((acc, obj) => {
      const filteredArquivos = obj?.listaArquivos?.filter((arquivo) => !Object.prototype.hasOwnProperty.call(arquivo, 'anexadoEm'));
      return [...acc, ...filteredArquivos];
    }, []);
    return result?.length > 0 ? { filesToAdd: result } : null;
  },
);

const anexosComplementares = createSelector(
  (state) => state?.listaDocumentosComplementares?.listaDados,
  (listaDados) => {
    const result = listaDados.reduce((acc, obj) => {
      const filesToDelete = [
        ...obj.documentoPendente
          .filter((documento) => documento.paraExcluir && documento.documentoId)
          .map((documento) => documento.documentoId),
        ...(Array.isArray(obj.idsDocumentosExcluidos) ? obj.idsDocumentosExcluidos : []),
      ];
      const filteredDocumentos = obj.documentoPendente
        .filter((documento) => documento.temporario && !documento.paraExcluir)
        .map((documento) => ({
          NomeOriginal: documento.documentoNome,
          NomeGuid: documento.nomeGuid,
          Tamanho: documento.documentoTamanho,
          IdPessoaTipoDocumentoJuridico: documento.pessoaTipoDocumentoId,
        }));
      return {
        filesToAdd: [...acc.filesToAdd, ...filteredDocumentos],
        filesToDelete: [...acc.filesToDelete, ...filesToDelete],
      };
    }, { filesToAdd: [], filesToDelete: [] });
    return result.filesToAdd.length > 0 || result.filesToDelete.length > 0 ? result : null;
  },
);

export default {
  documentosCadastro,
  documentosJuridico,
  documentosFormalizar,
  pendenciaDocumentosFormalizar,
  checkNewAnexosDocumentosFormalizar,
  anexosFormalizar,
  anexosComplementares,
};
