import {
  React, useState,
} from 'react';
import PropTypes from 'prop-types';

import SummaryPage from 'features/common/summaryPage';
import DocumentoFormalizarForm from '../documentoFormalizarForm';
import { getDocumentStatus, renderStatusItens } from '../documentoFormalizarStatus/statusUtils';

const DocumentosFormalizarItem = ({
  item, indexList,
}) => {
  const [openAccordion, setOpenAccordion] = useState(false);

  const renderItemStatusDealer = () => {
    const statusSummary = getDocumentStatus(item);
    return renderStatusItens(statusSummary);
  };

  return (
    <SummaryPage
      level={5}
      title={item?.nome}
      open={openAccordion}
      handleOpenChange={setOpenAccordion}
      IconTitle={renderItemStatusDealer()}
    >
      <DocumentoFormalizarForm
        documento={item}
        indexList={indexList}
        editMode
      />
    </SummaryPage>
  );
};

DocumentosFormalizarItem.propTypes = {
  item: PropTypes.object,
  indexList: PropTypes.number.isRequired,
};

DocumentosFormalizarItem.defaultProps = {
  item: {},
};

export default DocumentosFormalizarItem;
