import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FileButton from 'features/common/fileButton/fileButton';

const UploadDocumento = ({
  uploadDocumento,
  downloadDocumento,
  deleteDocumento,
  setErroDocumento,
  anexoRemovido,
  idDocumento,
  documento,
  showDeleteButton,
}) => {
  const MEGABYTE_CONVERSOR = 1048576.0;
  const LABEL = 'Documento';
  const ACCEPT = '.pdf,.png,.jpg,.jpeg';
  const [arquivoCarregado, setArquivoCarregado] = useState(null);
  const isError = documento?.isError || (!documento?.nome && anexoRemovido);

  const converterBytesParaMb = (bytes) => Math.round((bytes / MEGABYTE_CONVERSOR) * 1000) / 1000;

  const validatefile = (file) => {
    if (converterBytesParaMb(file.size) > 5.0) {
      setErroDocumento(documento?.idPessoaDocumento, documento?.nomeDocumento, 'Erro ao inserir arquivo. O tamanho excede o limite 5mb');
      return false;
    }
    if (!(file.type.includes('image/') || file.type === 'application/pdf')) {
      setErroDocumento(documento?.idPessoaDocumento, documento?.nomeDocumento, 'Erro ao inserir arquivo. O arquivo deve se tratar de uma imagem ou um pdf');
      return false;
    }
    return true;
  };

  const upload = async (file) => {
    if (validatefile(file)) {
      await uploadDocumento(file, documento?.idPessoaDocumento, documento?.nomeDocumento);
      setArquivoCarregado(file);
    }
  };

  const download = () => {
    if (arquivoCarregado) {
      const blobUrl = URL.createObjectURL(arquivoCarregado);
      window.open(blobUrl, '_blank');
    } else {
      downloadDocumento(idDocumento, documento?.idPessoaDocumento, documento?.nomeDocumento);
    }
  };

  const deleteFile = () => {
    deleteDocumento(documento?.idPessoaDocumento, documento?.nomeDocumento);
    setArquivoCarregado(null);
  };

  return (

    <FileButton
      downloadFile={download}
      uploadFile={upload}
      deleteFile={deleteFile}
      erro={isError}
      nome={documento?.nome}
      tamanho={documento?.tamanho}
      isLoading={documento?.isLoading}
      textoErro={documento?.textoErro}
      label={LABEL}
      accept={ACCEPT}
      showDeleteButton={showDeleteButton}
      isDocumentoCarregado={Boolean(arquivoCarregado)}
    />
  );
};

UploadDocumento
  .propTypes = {
    uploadDocumento: PropTypes.func,
    downloadDocumento: PropTypes.func,
    deleteDocumento: PropTypes.func,
    setErroDocumento: PropTypes.func,
    anexoRemovido: PropTypes.bool,
    showDeleteButton: PropTypes.bool,
    idDocumento: PropTypes.number,
    documento: PropTypes.object,
  };

UploadDocumento
  .defaultProps = {
    uploadDocumento: () => {},
    downloadDocumento: () => {},
    deleteDocumento: () => {},
    setErroDocumento: () => {},
    anexoRemovido: false,
    showDeleteButton: true,
    idDocumento: null,
    documento: null,
  };

export default UploadDocumento;
