import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { ButtonBase } from '@material-ui/core';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClearIcon from '../../../assets/icons/clear';
import { useStyles } from './fileButton.style';
import ButtonTooltipIcon from '../buttonTooltipIcon';

const FileButton = ({
  label,
  disabled,
  isLoading,
  accept,
  nome,
  tamanho,
  uploadFile,
  downloadFile,
  deleteFile,
  erro,
  textoErro,
  showDeleteButton,
  isDocumentoCarregado,
  isOnlyDownload,
}) => {
  const fileInput = useRef();
  const isDocumentoAnexado = Boolean(nome && tamanho);

  const getBorderColor = () => {
    if (erro) return '#C31E10';
    if (isDocumentoCarregado) return '#206446';
    return '#E5E6F25C';
  };
  const getPlaceholder = () => {
    if (isDocumentoAnexado) return `${nome} (${(tamanho)}mb)`;
    return 'Insira um documento';
  };

  const onClickButton = async () => {
    if (isDocumentoAnexado) {
      await downloadFile();
    }
    if (!disabled && !isDocumentoAnexado) {
      fileInput.current.value = null;
      fileInput.current.click();
    }
  };

  const onChangeInput = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (e.target.files && e.target.files.length > 0) {
      uploadFile(e.target.files[0]);
    }
  };

  const classes = useStyles({
    borderColor: getBorderColor(),
    documentoAnexado: isDocumentoAnexado,
  });
  return (
    <div className={classes.updateButton}>
      {label
    && (
      <div className={classes.textoLabel}>
        {label}
      </div>
    )}
      <div className={classes.container}>
        <ButtonBase
          disabled={isLoading || disabled}
          disableRipple
          variant="text"
          onClick={() => { onClickButton(); }}
          style={{ width: '100%' }}
        >
          <div className={classes.boxBotao}>
            {isLoading ? (
              <CircularProgress
                size="16px"
                className={classes.icone}
              />
            ) : (
              !isOnlyDownload && <AttachFileOutlinedIcon fontSize="small" className={classes.icone} />
            )}
            <span className={classes.textoDocumento}>
              {getPlaceholder()}
            </span>
            {isOnlyDownload && <FileDownloadOutlinedIcon fontSize="small" className={classes.downloadIcone} />}
          </div>
          {!isDocumentoAnexado && (
          <input
            className={classes.input}
            type="file"
            accept={accept}
            ref={fileInput}
            onChange={onChangeInput}
          />
          )}
        </ButtonBase>
        {showDeleteButton && (isDocumentoAnexado || isDocumentoCarregado)
        && (
          <ButtonTooltipIcon className={classes.botaoExcluir} buttonAction={deleteFile} title="Excluir">
            <ClearIcon width="16px" height="16px" />
          </ButtonTooltipIcon>
        )}
      </div>
      {textoErro
    && (
      <span className={classes.textoError}>
        {textoErro}
      </span>
    )}
    </div>
  );
};

FileButton.propTypes = {
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  erro: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
  accept: PropTypes.string,
  uploadFile: PropTypes.func,
  downloadFile: PropTypes.func,
  deleteFile: PropTypes.func,
  nome: PropTypes.string,
  label: PropTypes.string,
  tamanho: PropTypes.string,
  textoErro: PropTypes.string,
  isDocumentoCarregado: PropTypes.bool,
  isOnlyDownload: PropTypes.bool,
};

FileButton.defaultProps = {
  isLoading: false,
  disabled: false,
  accept: null,
  erro: false,
  nome: null,
  tamanho: null,
  label: null,
  textoErro: null,
  showDeleteButton: true,
  uploadFile: () => {},
  downloadFile: () => {},
  deleteFile: () => {},
  isDocumentoCarregado: false,
  isOnlyDownload: false,
};

export default FileButton;
