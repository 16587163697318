import { connect } from 'react-redux';
import operations from '../redux/operations';
import operationsPage from '../../limitesAprovadosPage/redux/operations';
import ButtonsFooter from './buttonsFooter';
import selector from '../redux/selector';

const mapStateToProps = ({ limitesAprovadosCadastro }) => ({
  documentosCadastro: selector.documentosCadastro(limitesAprovadosCadastro),
  documentosJuridico: selector.documentosJuridico(limitesAprovadosCadastro),
  documentosFormalizar: selector.documentosFormalizar(limitesAprovadosCadastro),
  existNewAnexos: selector.checkNewAnexosDocumentosFormalizar(
    limitesAprovadosCadastro.listaDocumentosFormalizar,
  )?.status,
  anexosFormalizar: selector.anexosFormalizar(limitesAprovadosCadastro),
  anexosComplementares: selector.anexosComplementares(limitesAprovadosCadastro),
});

const mapDispatchToProps = (dispatch) => ({
  enviarProposta: async (
    idLimite,
    status,
    anexosFormalizar,
    anexosComplementares,
  ) => {
    const result = await dispatch(
      operations.updateAndSaveStatus(
        idLimite,
        status,
        anexosFormalizar,
        anexosComplementares,
      ),
    );
    if (result) dispatch(operationsPage.setCadastroPage(false));
  },
  removeNewAnexos: () => dispatch(
    operations.removeNewAnexosDocumentoFormalizar(),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ButtonsFooter);
