import { React } from 'react';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import PendingActionsRoundedIcon from '@mui/icons-material/PendingActionsRounded';
import { Stack } from '@mui/material';
import StatusItem from './statusItem';

export function getDocumentStatus(document) {
  const statusList = [];
  const hasPendenteAnexo = document?.documentoPendente.some((file) => !file.documentoNome);
  // TO DO: confirmar se hasPendenteEnvio detecta os anexos não enviados
  const hasPendenteEnvio = document?.documentoPendente.some((file) => !file.anexadoEm);

  if (hasPendenteAnexo) statusList.push('Pendente anexo');
  if (hasPendenteEnvio) statusList.push('Pendente envio');
  if (!hasPendenteAnexo && !hasPendenteEnvio) statusList.push('Enviado');

  return statusList;
}

export function renderStatusItens(documentsStatus) {
  const allValidated = Array.isArray(documentsStatus)
    ? documentsStatus?.every((status) => status === 'Enviado')
    : documentsStatus === 'Enviado';

  const statusConfigs = [
    {
      condition: documentsStatus?.includes('Pendente envio'),
      icon: <PendingActionsRoundedIcon sx={{ fontSize: '16px', color: '#BF8900' }} />,
      color: '#BF8900',
      text: 'Pendente envio',
    },
    {
      condition: documentsStatus?.includes('Pendente anexo'),
      icon: <CancelScheduleSendIcon sx={{ fontSize: '16px', color: '#BF8900' }} />,
      color: '#BF8900',
      text: 'Pendente anexo',
    },
    {
      condition: documentsStatus?.length > 0 && allValidated,
      icon: <CheckRoundedIcon sx={{ fontSize: '16px', color: '#06C270' }} />,
      color: '#06C270',
      text: 'Enviado',
    },
  ];

  return (
    <Stack columnGap="4px" direction="row" alignItems="center">
      {statusConfigs.map(({
        condition, icon, color, text,
      }, index) => condition && <StatusItem key={index} icon={icon} color={color} text={text} />)}
    </Stack>
  );
}
