import { connect } from 'react-redux';
import DocumentosFormalizar from './documentosFormalizar';

const mapStateToProps = ({ limitesAprovadosCadastro }) => ({
  listaDocumentosFormalizar: limitesAprovadosCadastro.listaDocumentosFormalizar.listaDados,
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentosFormalizar);
